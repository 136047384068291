<template>
  <MarkCategories />
</template>

<script>
import MarkCategories from '@/views/administration/management/markCategories/components/markCategories'

export default {
  name: 'Index',
  components: { MarkCategories },
}
</script>

<style scoped>

</style>
